import React, { useState, useEffect } from 'react';
import './PageAlerta.css';

/* global gtag */ // Incluir gtag como variable global

const PageAlerta = () => {
  const [alerta, setAlerta] = useState(null); // Estado para almacenar los datos de la alerta
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado para manejar errores

  // Función para obtener los datos de la alerta desde Strapi
  useEffect(() => {
    const fetchAlerta = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/alerta`);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();

        // Guardamos los datos de la alerta en el estado
        setAlerta(data.data.attributes); 
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchAlerta(); // Llamamos a la función
  }, []);

  // Manejar el clic en el enlace para enviar el evento a Google Analytics
  const handleClick = () => {
    if (window.gtag) {
      window.gtag('event', 'alerta', {
        event_category: 'engagement',
        event_label: alerta.link,
        value: 1
      });
    }
  };

  // Si está cargando, mostramos un texto de carga
  if (loading) {
    return <div>Cargando...</div>;
  }

  // Si hay un error, lo mostramos
  if (error) {
    return <div>Error: {error}</div>;
  }

  // Si no hay datos, no mostramos nada
  if (!alerta) {
    return null;
  }

  // Renderizamos el contenido de la alerta si hay datos
  return (
    <div className="page-alerta-bar">
      <a 
        href={alerta.link} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="page-alerta-link"
        onClick={handleClick} // Enviamos el evento a Google Analytics cuando se hace clic
      >
        {alerta.titulo}
      </a>
    </div>
  );
};

export default PageAlerta;
