/* global gtag */

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import CloseSearchIcon from '@mui/icons-material/HighlightOff';
import SearchButtonIcon from '@mui/icons-material/Search'; 
import { MeiliSearch } from 'meilisearch';
import './PageNavbarDesktopSearch.css'

const PageNavbarDesktopSearch = ({ isHome, isSection1, forceNormalNavbar, setForceNormalNavbar, setIsSearchActive }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const searchBarRef = useRef(null);
  const searchResultsRef = useRef(null);
  const navigate = useNavigate();

  const client = new MeiliSearch({
    host: process.env.REACT_APP_MEILI_HOST,
    apiKey: process.env.REACT_APP_MEILI_KEY,
  });

  const handleSearch = async () => {
    if (searchQuery.trim() === '') {
      setSearchResults([]);
      setNoResults(false);
      return;
    }
  
    setIsSearching(true);
  
    try {
      const index = client.index('modelo');
      const results = await index.search(searchQuery, { limit: 5 });
  
      if (results.hits.length > 0) {
        setSearchResults(results.hits);
        setNoResults(false);

        gtag('event', 'search', {
          search_term: searchQuery,
          search_results: results.hits.length,
        });
  
      } else {
        setSearchResults([]);
        setNoResults(true);
  
        gtag('event', 'search', {
          search_term: searchQuery,
          search_results: 0,
        });
      }
  
      setSearchOpen(true); // Activar la barra de búsqueda
      setForceNormalNavbar(true);  // Forzar estilo normal
      setIsSearchActive(true);  // Notificar que la búsqueda está activa
    } catch (error) {
      console.error('Error durante la búsqueda:', error);
      setNoResults(true);
    } finally {
      setIsSearching(false);
    }
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const toggleSearch = () => {
    if (searchOpen) {
      setSearchOpen(false);
      setSearchResults([]);
      setNoResults(false);
      setSearchQuery('');
      if (isSection1 && isHome) {
        setForceNormalNavbar(false);  // Restaurar transparencia si estamos en Home y Section 1
      }
      setIsSearchActive(false);  // Notificar que la búsqueda ya no está activa
    } else {
      setSearchOpen(true);
      setForceNormalNavbar(true);  // Forzar estilo normal
      setIsSearchActive(true);  // Notificar que la búsqueda está activa
    }
  };

  const handleViewMore = () => {
    navigate('/productos');
    toggleSearch();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target) &&
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target)
      ) {
        setSearchOpen(false);
        setSearchQuery('');
        setIsSearchActive(false);  // Notificar que la búsqueda ya no está activa
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="page-navbar-desktop-search" ref={searchBarRef}>
      {searchOpen ? (
        <div className="page-navbar-desktop-search-bar">
          <input
            type="text"
            value={searchQuery}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Busca nuestros productos"
            className="page-navbar-desktop-search-input"
          />
          <SearchButtonIcon
            className={`page-navbar-desktop-search-button-icon ${isSearching ? 'active' : ''}`}
            onClick={handleSearch}
          />
          <CloseSearchIcon className="page-navbar-desktop-search-close-icon" onClick={toggleSearch} />
        </div>
      ) : (
        <SearchIcon className="page-navbar-desktop-search-icon" onClick={toggleSearch} />
      )}

      {searchOpen && searchQuery && (
        <div className="page-navbar-desktop-search-results" ref={searchResultsRef}>
          {noResults ? (
            <div className="page-navbar-desktop-search-no-results">No se encontraron resultados</div>
          ) : (
            searchResults.slice(0, 5).map((result) => (
              <div key={result.id} className="page-navbar-desktop-search-result-item">
                <a href={`/modelo/${result.slug}`} onClick={toggleSearch}>
                  {result.nombre}
                </a>
              </div>
            ))
          )}

          {searchQuery && (
            <div className="page-navbar-desktop-search-result-item page-navbar-desktop-search-view-more" onClick={handleViewMore}>
              Ver más productos
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PageNavbarDesktopSearch;