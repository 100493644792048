import React, { useEffect, useRef } from 'react';
import './Home.css';
import PageFooter from '../components/PageFooter';
import HomeBannerSection from '../components/Home/HomeBannerSection/HomeBannerSection';
import HomeShowcaseSection from '../components/Home/HomeShowcaseSection/HomeShowcaseSection';
import HomeContactoSection from '../components/Home/HomeContactoSection/HomeContactoSection';

const Home = () => {
  // Ref for section 1
  const section1Ref = useRef(null);

  // Cambiar el título y la meta descripción al montar el componente
  useEffect(() => {
    document.title = "Zoomlion Latinoamérica";

    // Actualizar la meta descripción
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content = "Descubre la maquinaria de alta calidad de Zoomlion Latinoamérica, diseñada con tecnología avanzada, sustentabilidad y experiencia. Soluciones innovadoras para la construcción, minería y agricultura.";

    // Scroll a la sección 1 cuando se monte el componente
    if (section1Ref.current) {
      section1Ref.current.scrollIntoView({ behavior: 'auto' });
    }
  }, []);

  // Create a ref for the HomeContact section
  const contactSectionRef = useRef(null);

  // Function to handle scroll to the HomeContact section
  const scrollToContact = () => {
    if (contactSectionRef.current) {
      contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="section section1" ref={section1Ref}>
        <HomeBannerSection scrollToContact={scrollToContact} />
      </div>
      <div className="section section2"><HomeShowcaseSection /></div>
      <div className="section section3" ref={contactSectionRef}><HomeContactoSection/></div>
      <div className="section fp-auto-height">
        <PageFooter />
      </div>
    </>
  );
};

export default Home;
