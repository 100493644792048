import { useLocation } from 'react-router-dom';
import React, { useState, useEffect, Suspense } from 'react';
import './Contacto.css';
import PageFooter from '../components/PageFooter';

// Lazy load components
const LazyPageBanner = React.lazy(() => import('../components/PageBanner'));
const LazyContactForm = React.lazy(() => import('../components/Contacto/ContactForm'));
const LazyContactCard = React.lazy(() => import('../components/Contacto/ContactCard'));
const LazyContactMap = React.lazy(() => import('../components/Contacto/ContactMap'));

const Contacto = () => {

  const location = useLocation();
  const modelo = location.state?.modelo || null;

  const [selectedOficina, setSelectedOficina] = useState(null);

  // Cambiar el título y la meta descripción al montar el componente
  useEffect(() => {
    document.title = "Zoomlion Latinoamérica | Contacto";

    // Actualizar la meta descripción
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content = "Conoce las oficinas y centros de atención de Zoomlion en Latinoamérica y ponte en contacto con nosotros.";

    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  // Function to update the selected office
  const handleOfficeChange = (oficina) => {
    setSelectedOficina(oficina);
  };

  return (
    <div className="contact-page">
      <div className="contact-page-banner">
        {/* Lazy load the PageBanner */}
        <Suspense fallback={<div>Cargando Banner...</div>}>
          <LazyPageBanner />
        </Suspense>
      </div>
      <div className="contact-page-content">
        <div className="contact-page-information">
          <div className="contact-page-card">
            {/* Lazy load the ContactCard */}
            <Suspense fallback={<div>Cargando Oficinas...</div>}>
              <LazyContactCard onOfficeChange={handleOfficeChange} />
            </Suspense>
          </div>
          <div className="contact-page-form">
            {/* Lazy load the ContactForm */}
            <Suspense fallback={<div>Cargando Formulario de Contacto...</div>}>
              <LazyContactForm selectedOficina={selectedOficina} modelo={modelo} />
            </Suspense>
          </div>
        </div>
      </div>
      <div className="contact-page-map">
        {/* Lazy load the ContactMap */}
        <Suspense fallback={<div>Cargando Map...</div>}>
          <LazyContactMap oficina={selectedOficina} />
        </Suspense>
      </div>
      <div className="contact-page-footer">
        <PageFooter />
      </div>
    </div>
  );
};

export default Contacto;
