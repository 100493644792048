// src/components/Modelo/ModeloCotizacion.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './ModeloCotizacion.css';

const ModeloCotizacion = ({ modelo }) => {

  const navigate = useNavigate(); // Definir navigate

  return (
    <div className="modelo-cotizacion">
      <div className="modelo-cotizacion-button-container" onClick={() => navigate('/contacto', { state: { modelo } })}>
        <div className="modelo-cotizacion-button-content">
          <span>SOLICITAR COTIZACIÓN</span>
        </div>
        <div className="modelo-cotizacion-button-arrow">
          <FontAwesomeIcon icon={faAngleRight} className="modelo-cotizacion-button-arrow-icon" />
        </div>
      </div>
    </div>
  );
};

export default ModeloCotizacion;
