import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Modelo.css';
import ModeloSpecs from '../components/Modelo/ModeloSpecs';
import ModeloTable from '../components/Modelo/ModeloTable';
import PageFooter from '../components/PageFooter';
import ModeloCotizacion from '../components/Modelo/ModeloCotizacion';
import ModeloHeaderWrapper from '../components/Modelo/ModeloHeaderWrapper';
import ModeloDestacadoWrapper from '../components/Modelo/ModeloDestacado/ModeloDestacadoWrapper';
import PageAlerta from '../components/PageAlerta'; 

const Modelo = () => {
  const { slug } = useParams();  // Usar el slug en lugar de modeloId
  const navigate = useNavigate();
  const [modelo, setModelo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Nuevo useEffect para manejar scroll al top
  useEffect(() => {
    // Forzar el scroll a la posición 0,0 cuando el slug cambia
    window.scrollTo(0, 0);
  }, [slug]);  // Se ejecuta cada vez que el slug cambia

  useEffect(() => {
    const fetchModelData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/modelos?filters[slug][$eq]=${slug}&populate=linea,serie,imagen_card,especificaciones`);
        const data = await response.json();

        if (!response.ok || !data.data || data.data.length === 0) {
          throw new Error(`Error fetching model details for slug: ${slug}`);
        }

        const modeloData = data.data[0].attributes;  // El slug debería devolver un solo modelo
        const serieData = modeloData.serie?.data?.attributes;
        const lineaData = modeloData.linea?.data?.attributes;

        modeloData.serie = {
          id: modeloData.serie?.data?.id,  
          nombre: serieData?.nombre
        };
        modeloData.linea = {
          id: modeloData.linea?.data?.id,  
          nombre: lineaData?.nombre
        };

        modeloData.imagenCardUrl = modeloData.imagen_card?.data?.attributes?.url || '/images/default-banner.webp';

        setModelo(modeloData);
      } catch (error) {
        console.error("Error fetching model data:", error);
        setError("Error loading model data.");
      } finally {
        setLoading(false);
      }
    };

    fetchModelData();
  }, [slug]);  // Actualiza cuando el slug cambia

  // Cambiar el título y la meta descripción cuando el modelo esté disponible
  useEffect(() => {
    if (modelo) {
      document.title = `Zoomlion Latinoamérica | ${modelo.nombre}`;

      // Actualizar la meta descripción
      let metaDescription = document.querySelector('meta[name="description"]');
      if (!metaDescription) {
        metaDescription = document.createElement('meta');
        metaDescription.name = "description";
        document.head.appendChild(metaDescription);
      }
      metaDescription.content = modelo.descripcion || "Descripción no disponible.";
    }
  }, [modelo]);

  if (loading) {
    return (
      <div className="modelo-page-loading">
        <div className="spinner">Cargando...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="modelo-page-error">
        <h2>Ocurrió un error</h2>
        <p>{error}</p>
        <button onClick={() => navigate(-1)} className="modelo-page-button">
          Volver
        </button>
      </div>
    );
  }

  if (!modelo) {
    return <div>Error al cargar los detalles del modelo.</div>;
  }

  const hasEspecificaciones = modelo.especificaciones && modelo.especificaciones.length > 0;
  const headerClass = modelo.descripcion ? "modelo-page-header" : "modelo-page-header reduced-header-mobile";

  return (
    <>
      <div className='modelo-page-alerta'>      
        <PageAlerta />
      </div>

      <div className={headerClass}>
        <ModeloHeaderWrapper
          nombre={modelo.nombre}
          serie={modelo.serie} 
          linea={modelo.linea}  
          descripcion={modelo.descripcion}
          imagenUrl={modelo.imagenCardUrl}
        />
      </div>

      <div className="modelo-page-container">
        {hasEspecificaciones && (
          <div className="modelo-page-detail">
            {modelo.especificaciones.length === 3 && (
              <ModeloSpecs specs={modelo.especificaciones} />
            )}

            {modelo.especificaciones.length > 3 && (
              <>
                <ModeloSpecs specs={modelo.especificaciones} />
                <ModeloTable specs={modelo.especificaciones} />
              </>
            )}
          </div>
        )}
      </div>
      <ModeloCotizacion modelo={modelo} />

      
      <div className="modelo-page-destacado">
        <ModeloDestacadoWrapper linea={modelo.linea} />
       </div>


      <div className="modelo-page-footer">
        <PageFooter />
      </div>
    </>
  );
};

export default Modelo;
