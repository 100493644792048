// PageNavbarDesktop.js

import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './PageNavbarDesktop.css';
import PageNavbarDesktopSearch from './PageNavbarDesktopSearch';
import Submenu from './Submenu';
import logo from '../../../images/logo.webp';

const PageNavbarDesktop = () => {
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const submenuTimeoutRef = useRef(null);
  const [isSection1, setIsSection1] = useState(true);  // Estado para controlar la sección
  const [forceNormalNavbar, setForceNormalNavbar] = useState(false);  // Estado para forzar estilo normal
  const [isSearchActive, setIsSearchActive] = useState(false);  // Estado de búsqueda activa
  const [hoveringLink, setHoveringLink] = useState(false);  // Estado de hover sobre links
  const location = useLocation();

  const isHome = location.pathname === "/";  // Verificar si estamos en Home

  // Listener para detectar cambios de sección y aplicar/quitar transparencia
  useEffect(() => {
    const handleSectionChange = (event) => {
      if (isHome) {
        setIsSection1(event.detail === 0);  // Estamos en Section 1 si event.detail es 0
        if (event.detail === 0 && !forceNormalNavbar) {
          setForceNormalNavbar(false);  // Restaurar transparencia solo en Home y Section 1
        }
      }
    };

    if (isHome) {
      setIsSection1(true);  // Al iniciar, asumimos que estamos en la primera sección
      setForceNormalNavbar(false);  // Forzar estilo transparente al iniciar en Home y Section 1
    }

    document.addEventListener('sectionChange', handleSectionChange);

    return () => {
      document.removeEventListener('sectionChange', handleSectionChange);
    };
  }, [forceNormalNavbar, isHome]);

  // Condición para determinar si el navbar debe ser transparente
  const shouldBeTransparent = isHome && isSection1 && !submenuOpen && !isSearchActive && !hoveringLink;

  // Hover sobre links de navegación: desactivar transparencia
  const handleLinkMouseEnter = () => {
    setHoveringLink(true);
    setForceNormalNavbar(true);  // Forzar estilo normal
  };

  const handleLinkMouseLeave = () => {
    setHoveringLink(false);
    if (isSection1 && isHome && !submenuOpen && !isSearchActive) {
      setForceNormalNavbar(false);  // Restaurar transparencia si se cumplen las condiciones
    }
  };

  // Submenú: abrir/cerrar y controlar transparencia
  const handleMouseEnter = () => {
    if (submenuTimeoutRef.current) {
      clearTimeout(submenuTimeoutRef.current);
      submenuTimeoutRef.current = null;
    }
    setSubmenuOpen(true);
    setForceNormalNavbar(true);  // Forzar estilo normal
  };

  const handleMouseLeave = () => {
    submenuTimeoutRef.current = setTimeout(() => {
      setSubmenuOpen(false);
      if (isSection1 && isHome && !hoveringLink && !isSearchActive) {
        setForceNormalNavbar(false);  // Restaurar transparencia
      }
    }, 200);
  };

  return (
    <nav className={`page-navbar ${shouldBeTransparent ? 'page-navbar-transparent' : ''}`}>
      <div className="page-navbar-content">
        <div className="page-navbar-logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>

        <div className="page-navbar-links-container">
          <div className={`page-navbar-links-desktop ${shouldBeTransparent ? 'page-navbar-white-links' : ''}`}>
            <Link
              to="/"
              onMouseEnter={handleLinkMouseEnter}
              onMouseLeave={handleLinkMouseLeave}
            >
              INICIO
            </Link>
            <div
              className="navbar-productos-container"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Link
                to="/productos"
                className="navbar-productos"
                onMouseEnter={handleLinkMouseEnter}
                onMouseLeave={handleLinkMouseLeave}
              >
                PRODUCTOS
              </Link>
              {submenuOpen && <Submenu onClose={() => setSubmenuOpen(false)} />}
            </div>
            <Link
              to="/noticias"
              onMouseEnter={handleLinkMouseEnter}
              onMouseLeave={handleLinkMouseLeave}
            >
              NOTICIAS
            </Link>
            <Link
              to="/contacto"
              onMouseEnter={handleLinkMouseEnter}
              onMouseLeave={handleLinkMouseLeave}
            >
              CONTACTO
            </Link>
          </div>

          <PageNavbarDesktopSearch
            isHome={isHome}
            isSection1={isSection1}
            forceNormalNavbar={forceNormalNavbar}
            setForceNormalNavbar={setForceNormalNavbar}
            setIsSearchActive={setIsSearchActive}  // Controlar si la búsqueda está activa
          />
        </div>
      </div>
    </nav>
  );
};

export default PageNavbarDesktop;
